import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Fade from 'react-reveal/Fade'
import FiftyProjects from '../components/FiftyProjects'

const Portafolio = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  })

  return (
    <Container>
      <Fade>
        <div className='blog-titulo text-center'>
          <h1>PORTFOLIO</h1>
          <p>Esto es lo que puedo hacer por ti</p>
        </div>
      </Fade>

      <hr />

      <a href='https://calculadorasolar.matiascastellontest.info'>
        <Row style={{ marginBottom: '2rem' }} className='shadow-sm rounded portfolio-web-ejemplo'>
          {/* la imagen queda centrada verticalmente */}
          <Col lg={3} className='ps-0 text-center'>
            <img src="../imagenes/portfolio/tecsolmed.jpg" alt="imagen" style={{ width: 250 }} />
          </Col>
          <Col lg={9} className='py-3 pe-5'>
            {/* adds lowecase */}
            <h2 className='px-3 px-md-4 titulo-portfolio'>Calculadora de presupuestos + Integración API Google Maps</h2>
            <p className='px-3 px-md-4'> Tecsolmed buscaba una forma rápida de ofrecer presupuestos para la instalación de paneles solares según la superficie del tejado. </p>
            <p className='px-3 px-md-4'> Introduce tu dirección, localiza tu casa y dibuja en el mapa la superficie para el panel solar. Se calcula un precio y se muestran datos relevantes. </p>
            <p className='px-3 px-md-4'><span className="fw-bold">Competencias utilizadas</span>:  API Google Maps <i class="fa-brands fa-google"></i>, Javascript <i class="fa-brands fa-js"></i>, CSS</p>
          </Col>
        </Row>
      </a>

      <a href='https://github.com/mattcoco/datascraper'>
        <Row style={{ marginBottom: '2rem' }} className='shadow-sm rounded portfolio-web-ejemplo'>
          <Col lg={3} className='ps-0 text-center'>
            <img src="../imagenes/portfolio/datascraper.gif" alt="gif de data scraper mostrando resultados de búsqueda en masa" style={{ width: 250 }} />
          </Col>
          <Col lg={9} className='py-3 pe-5'>
            <h2 className='px-3 px-md-4 titulo-portfolio'>Data Scraper</h2>
            <p className='px-3 px-md-4'> Un script para extraer listados de lugares del tipo "agencial digital en valencia" y presentarlos en un archivo .csv, importable en Excel.</p>
            <p className='px-3 px-md-4'> Requiere una API key de Google Places. </p>
            <p className='px-3 px-md-4'><span className="fw-bold">Competencias</span>:  API Google Places <i class="fa-brands fa-google"></i>, Javascript <i class="fa-brands fa-js"></i>, CSS, Node.js</p>
          </Col>
        </Row>
      </a>

      <a href='../ejemplo-3'>
        <Row style={{ marginBottom: '2rem' }} className='shadow-sm rounded portfolio-web-ejemplo'>
          <Col lg={3} className='ps-0 text-center'>
            <img src="../imagenes/portfolio/ejemplo3_thumbnail.png" alt="imagen" style={{ height: 250 }} />
          </Col>
          <Col lg={9} className='py-3 pe-5'>
            <h2 className='px-3 px-md-4 titulo-portfolio'>Web de venta de Software</h2>
            <p className='px-3 px-md-4'>Diseño web realizado con Wordpress y Elementor para una empresa de software. </p>
            <p className='px-3 px-md-4'><span className="fw-bold">Competencias utilizadas</span>:  Wordpress <i class="fa-brands fa-wordpress"></i>, Elementor <i class="fa-brands fa-elementor"></i>, WooCommerce</p>
          </Col>
        </Row>
      </a>
      <a href='https://artemistienda.matiascastellontest.info'>
        <Row style={{ marginBottom: '2rem' }} className='shadow-sm rounded portfolio-web-ejemplo'>
          <Col lg={3} className='ps-0 text-center'>
            <img src="../imagenes/portfolio/ejemplo2_thumbnail.png" alt="imagen" style={{ height: 250 }} />
          </Col>
          <Col lg={9} className='py-3 pe-5'>
            <h2 className='px-3 px-md-4 titulo-portfolio'>Floristería y arreglos florales</h2>
            <p className='px-3 px-md-4'>Una tienda con diseño neutro y adaptable (responsive) para tiendas online. Basado en Wordpress y Elementor. Completamente personalizable y rápido de expeditar.</p>
            <p className='px-3 px-md-4'><span className="fw-bold">Competencias utilizadas</span>:  Wordpress <i class="fa-brands fa-wordpress"></i>, Elementor <i class="fa-brands fa-elementor"></i></p>
          </Col>
        </Row>
      </a>


      <a href='../ejemplo-1'>
        <Row style={{ marginBottom: '2rem' }} className='shadow-sm rounded portfolio-web-ejemplo'>
          <Col lg={3} className='ps-0 text-center'>
            <img src="../imagenes/portfolio/ejemplo1_thumbnail.jpg" alt="imagen" style={{ height: 250 }} />
          </Col>
          <Col lg={9} className='py-3 pe-5'>
            <h2 className='px-3 px-md-4 titulo-portfolio'>Landing para empresa de Marketing Digital</h2>
            <p className='px-3 px-md-4'>Diseño colorido para empresas de software o marketing. Basado en Wordpress y Elementor. Completamente personalizable y rápido de expeditar.</p>
            <p className='px-3 px-md-4'><span className="fw-bold">Competencias utilizadas</span>:  Wordpress <i class="fa-brands fa-wordpress"></i>, Elementor <i class="fa-brands fa-elementor"></i></p>
          </Col>
        </Row>
      </a>

      <Row style={{ marginBottom: '2rem' }} className='shadow-sm rounded portfolio-web-ejemplo'>
        <Col lg={3} className='ps-0 text-center'>
          <img src="../imagenes/portfolio/logo_disvimur_2021_negro_letrero.png" alt="imagen" style={{ height: 250 }} />
        </Col>
        <Col lg={9} className='py-3 pe-5'>
          <h2 className='px-3 px-md-4 titulo-portfolio'>Tienda Online - Wordpress personalizado + WooCommerce</h2>
          <p className='px-3 px-md-4'>Una tienda online de bebidas y conservas y con funcionalidad "carrito de compra" y que admite registro de usuarios. Tema hijo en wordpress hecho a código con PHP.</p>
          <p className='px-3 px-md-4'><span className="fw-bold">Competencias utilizadas</span>:  Wordpress <i class="fa-brands fa-wordpress"></i>, Elementor <i class="fa-brands fa-elementor"></i>, WooCommerce, CSS <i class="fa-brands fa-css3-alt"></i>, PHP <i class="fa-brands fa-php"></i></p>
        </Col>
      </Row>

      <Row style={{ marginBottom: '2rem' }} className='shadow-sm rounded portfolio-web-ejemplo'>
        <Col lg={3} className='ps-0  text-center'>
          <img src="../imagenes/portfolio/moodle-logo_500.png" alt="imagen" style={{ height: 250 }} />
        </Col>
        <Col lg={9} className='py-3 pe-5'>
          <h2 className='px-3 px-md-4 titulo-portfolio'>Plugins Moodle para conexión a Biblioteca Digital</h2>
          <p className='px-3 px-md-4'>Plugins de Moodle que permiten al usuario acceder a la biblioteca informática de Xercode e interactuar con ella; uso de PHP y Javascript, entorno Moodle, conexiones API y a la base de datos remota.</p>
          <p className='px-3 px-md-4'><span className="fw-bold">Competencias utilizadas</span>:  Moodle, PHP <i class="fa-brands fa-php"></i></p>
        </Col>
      </Row>
      <hr className='my-md-5' />

      <FiftyProjects />

    </Container >
  )
}

export default Portafolio
